import * as Sentry from '@sentry/remix'

import useCurrentUser from '~/hooks/useCurrentUser'

export default function useSetupSentryUser() {
  const user = useCurrentUser()

  if (user) {
    Sentry.setUser({ id: user.id, name: user.fullName, email: user.email })
  } else {
    Sentry.setUser(null)
  }
}

import cn from 'classnames'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

export default function NetworkIndicator() {
  const [status, setStatus] = useState<'online' | 'offline'>('online')

  useEffect(() => {
    const handleOnline = () => setStatus('online')
    const handleOffline = () => setStatus('offline')

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: status === 'offline' ? 0 : -100, transition: { delay: 2 } }}
      className="fixed w-full top-0"
    >
      <div className="text-center mt-3">
        <div className="inline-flex items-center shadow rounded px-4 py-2 bg-gray-900">
          <span className="relative flex h-3 w-3 mr-3">
            <span
              className={cn(
                status === 'offline' ? 'bg-red-400' : 'bg-green-400',
                'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 transition-colors'
              )}
            />
            <span
              className={cn(
                status === 'offline' ? 'bg-red-500' : 'bg-green-500',
                'relative inline-flex rounded-full h-3 w-3 transition-colors'
              )}
            />
          </span>
          <span className="text-white text-sm">
            {status === 'offline' ? 'Connection lost' : 'Connection restored'}
          </span>
        </div>
      </div>
    </motion.div>
  )
}
